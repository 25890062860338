// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-404-js": () => import("./../../../src/templates/404/404.js" /* webpackChunkName: "component---src-templates-404-404-js" */),
  "component---src-templates-activities-activities-js": () => import("./../../../src/templates/activities/activities.js" /* webpackChunkName: "component---src-templates-activities-activities-js" */),
  "component---src-templates-contact-contact-js": () => import("./../../../src/templates/contact/contact.js" /* webpackChunkName: "component---src-templates-contact-contact-js" */),
  "component---src-templates-error-error-js": () => import("./../../../src/templates/error/error.js" /* webpackChunkName: "component---src-templates-error-error-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-privacy-privacy-js": () => import("./../../../src/templates/privacy/privacy.js" /* webpackChunkName: "component---src-templates-privacy-privacy-js" */),
  "component---src-templates-success-success-js": () => import("./../../../src/templates/success/success.js" /* webpackChunkName: "component---src-templates-success-success-js" */)
}

